import { Button } from '@mediahuis/chameleon-react';

import { useZephrData } from '@hubcms/data-access-zephr';
import { createNavigationItemClickHandler } from '@hubcms/ui-navigation-2';

import styles from './subscription-button.module.scss';

export type SubscriptionButtonProps = {
  href: string;
  text: string;
};

function SubscriptionButton({ href, text }: SubscriptionButtonProps) {
  const { subscriber } = useZephrData();
  const isSubscriber = subscriber && Object.keys(subscriber).length > 0;
  const isSubscriptionEligible = !isSubscriber || subscriber.allowedProducts.length === 0;

  const onClick = createNavigationItemClickHandler({
    href,
    trackingName: text,
    trackingBlock: 'btnheader',
  });

  if (!isSubscriptionEligible) {
    return null;
  }

  return (
    <Button
      as="a"
      className={styles.subscriptionButton}
      data-testid="subscription-button"
      href={href}
      rel="noreferrer"
      size="sm"
      target="_blank"
      onClick={onClick}
    >
      {text}
    </Button>
  );
}

export default SubscriptionButton;
